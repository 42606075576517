





















import { Vue, Component } from 'vue-property-decorator';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import { noDataText } from '../lib/autocomplete';
import { handleError } from '../lib/shared';
import * as charts from '../lib/charts';
import * as _ from 'lodash';

// High Charts
import {Chart} from 'highcharts-vue';
import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import loadExporting from 'highcharts/modules/exporting';
import loadExportData from 'highcharts/modules/export-data';
import loadDrilldown from 'highcharts/modules/drilldown';

loadDrilldown(Highcharts);
loadExporting(Highcharts);
loadExportData(Highcharts);
HighchartsNoData(Highcharts);

@Component({
  components: {
    highcharts: Chart,
    SpinnerComponent,
  },
})
export default class ChartComponent extends Vue {

  public MAX_CHART_ITEMS: number = 10;
  public bInitialLoad: boolean = true;

  protected arrErrors: Error[] = [];

  private updateArgs: any = charts.getUpdateArgs();
  private updateCriteria: number = 0;
  private chartOptions: any = {};
  private bgColor: number = 0;

  protected getOptions(): any {
    return null;
  }

  protected mounted() {
    this.refreshCharts();
  }

  protected redrawCharts() {
      this.redrawComponentCharts();
      charts.resize();
  }

  // Override the following methods.
  protected isRefreshing(): boolean { return false; }
  protected redrawComponentCharts() { }
  protected refreshCharts() { }

  private getCardStyle(): string {
    let style: string = this.getStyle();
    style += (';margin-right:' + ((this.$vuetify.breakpoint.smAndDown) ? '0px' : '30px'));
    return style;
  }

  private getStyle(): string {
    const chartOptions: any = this.getOptions();
    const bgColor: string = chartOptions.chart ? chartOptions.chart.backgroundColor : '#18374B';
    const style = ('background-color: ' + bgColor);
    return style;
  }
}
