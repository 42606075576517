
import { Component, Prop } from 'vue-property-decorator';
import ChartComponent from '../components/ChartComponent.vue';
import { StackedBarMeta } from '../store/models';
import { EmptyStackedBarMeta } from '../store/models-empty';
import * as colors from '../lib/colors';
import * as charts from '../lib/charts';
import * as _ from 'lodash';

@Component
export default class BeneficiaryChartComponent extends ChartComponent {

  @Prop() public aggregate: any;
  @Prop() public overallTotal: any;
  @Prop() public includeCommunityContributions: any;
  @Prop() public includeIndigenousEmployment: any;
  @Prop() public title: any;
  @Prop() public maxChartItems: any;

  // Chart data.
  private totalContributionsToCommunities: any = {};
  private totalContributionsToCommunitiesTitle: string = 'Tangible Net Benefits by Indigenous Community';
  private totalContributionsToCommunitiesXLabel: string = '';
  private totalContributionsToCommunitiesYLabel: string = '';

  protected isRefreshing(): boolean {
    return false;
  }

  protected getOptions(): any {
    return this.totalContributionsToCommunities;
  }

  protected refreshCharts() {
    this.redrawComponentCharts();
  }

  protected redrawComponentCharts() {
      const stackedBarMeta: StackedBarMeta = _.cloneDeep(EmptyStackedBarMeta);
      stackedBarMeta.title = this.title ? this.title : this.totalContributionsToCommunitiesTitle;
      stackedBarMeta.xAxis = this.totalContributionsToCommunitiesXLabel;
      stackedBarMeta.yAxis = this.totalContributionsToCommunitiesYLabel;
      stackedBarMeta.categories = charts.getTnbBarChartCategories(this.aggregate, this.maxChartItems, true, this.includeCommunityContributions, this.includeIndigenousEmployment, false, false);
      stackedBarMeta.series = this.overallTotal ? charts.getTnbBarChartSeries(this.aggregate, this.maxChartItems, 'Tangible Net Benefits', true, this.overallTotal, this.includeCommunityContributions, this.includeIndigenousEmployment) : charts.getTnbBarChartSeries(this.aggregate, this.maxChartItems, 'Total Contributions', true, this.overallTotal, this.includeCommunityContributions, this.includeIndigenousEmployment, false, false);
      stackedBarMeta.colors = [ colors.colorProjectBenefits ];
      if (this.includeCommunityContributions) {
        stackedBarMeta.colors.push(colors.colorCommunityInvestment);
      }
      if (this.includeIndigenousEmployment) {
        stackedBarMeta.colors.push(colors.colorEmployment);
      }
      stackedBarMeta.totalStacks = 2;
      stackedBarMeta.formatNumbersToCurrency = true;
      stackedBarMeta.legendEnabled = !this.overallTotal;
      stackedBarMeta.bgColor = colors.colorLightGrey;
      this.totalContributionsToCommunities = charts.getStackedBarFromMeta(stackedBarMeta);
      charts.resize();
      this.bInitialLoad = false;
  }
}
