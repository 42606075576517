




















































































































































import { Component, Watch } from 'vue-property-decorator';

import BaseComponent from '../components/BaseComponent';

import { AggSearchCriteria, Expense, GeneralSearchCriteria, SearchExpenses } from '../store/models';
import { EmptyAggSearchCriteria, EmptyGeneralSearchCriteria, EmptySearchExpenses} from '../store/models-empty';
import { searchExpenses } from '../store/rest.service';

import AggregateHelper from '../components/AggregateHelper';
import ReportHelper, { ReportType } from '../components/ReportHelper';

import * as charts from '../lib/charts';
import * as datetime from '../lib/datetime';

import * as _ from 'lodash';

import TotalComponent from '../components/TotalComponent.vue';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import FiltersComponent from '../components/FiltersComponent.vue';
import DatePickerComponent from '../components/DatePickerComponent.vue';
import SocialProcurementCompactComponent from "../components/SocialProcurementCompactComponent.vue";
import DiverseSuppliersChartComponent from '../components/DiverseSuppliersChartComponent.vue';
import IndigenousProcurementInsightsComponent from "../components/IndigenousProcurementInsightsComponent.vue";
import ThirdPartyInsightsComponent from "../components/ThirdPartyInsightsComponent.vue";
import WorkforceDiversityComponent from "../components/WorkforceDiversityComponent.vue";
import IndigenousImpactComponent from "../components/IndigenousImpactComponent.vue";
import MobileBottomNavComponent from "../components/MobileBottomNavComponent.vue";
import TooltipComponent from "../components/TooltipComponent.vue";

// High Charts
import {Chart} from 'highcharts-vue';
import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import loadExporting from 'highcharts/modules/exporting';
import loadExportData from 'highcharts/modules/export-data';
import loadDrilldown from 'highcharts/modules/drilldown';
import loadDataTableToggle from '../lib/highcharts-datatable.js';
import { getQsExpenseAreaFilter, getQsRegionFilter } from '@/lib/facets';

loadDrilldown(Highcharts);
loadExporting(Highcharts);
loadExportData(Highcharts);
HighchartsNoData(Highcharts);
loadDataTableToggle(Highcharts);

@Component({
  components: {
    SpinnerComponent,
    FiltersComponent,
    DatePickerComponent,
    TotalComponent,
    SocialProcurementCompactComponent,
    DiverseSuppliersChartComponent,
    IndigenousProcurementInsightsComponent,
    ThirdPartyInsightsComponent,
    WorkforceDiversityComponent,
    IndigenousImpactComponent,
    MobileBottomNavComponent,
    TooltipComponent,
  },
})

export default class Insights extends BaseComponent {

  protected baseRoute: string = '/insights';
  private reportType: ReportType = ReportType.Unknown;

  // Filters.
  private years: number[] = datetime.getYears();
  private selectedYear: number = datetime.getYear();
  private minYear: number = datetime.getYear();
  private minYearLoading: boolean = true;
  private filterYearOnly: boolean = false;

  private searchCriteria: GeneralSearchCriteria =  _.cloneDeep(EmptyGeneralSearchCriteria);
  private aggSearchCriteria: AggSearchCriteria =  _.cloneDeep(EmptyAggSearchCriteria);

  private reportHelper: ReportHelper|null = null;
  private reportHelperLoading: boolean = true;
  private reportHelperUpdated: number = 0;

  private aggregateHelper: AggregateHelper = new AggregateHelper();
  private aggregateHelperLoading: boolean = true;
  private aggregateHelperUpdated: number = 0;

  private arrErrors: Error[] = [];

  private resizeCharts: number = 0;
  private updateArgs: any = [true, true, {duration: 100}];
  private bInitialLoad: boolean = true;
  private bUpdateClicked: boolean = false;
  private isFullScreen: boolean = false;
  private projectId: string = '';
  private unWatch1: any;

  protected mounted() {
    // Get the Project Id and set the Report Type.
    if (this.$route.params.id) {
      this.projectId = this.$route.params.id;
      this.baseRoute = '/projects';
      this.reportType = ReportType.InsightsProject;
    } else {
      this.baseRoute = '/insights';
      this.reportType = ReportType.Insights;
      this.filterYearOnly = true;
    }
    this.reportHelper = new ReportHelper(this.reportType);
    this.$el.addEventListener('fullscreenchange', this.fullScreenChanged);
    this.setSearchCriteria();
    this.getMinExpenseYear();
    this.refreshCharts();
  }

  protected beforeDestroy() {
    this.$el.removeEventListener('fullscreenchange', this.fullScreenChanged);
    if (this.unWatch1) {
      this.unWatch1();
    }
  }

  protected changeCurrentOrg() {
    if (this.projectId !== '') {
      this.navigateRoute(this.baseRoute);
    } else {
      this.setSearchCriteria();
      this.refreshCharts();
    }
  }

  @Watch('aggregateHelper.isLoading')
  protected onHelperChanged(val: any, oldVal: any) {
    this.aggregateHelperLoading = this.aggregateHelper.isLoading;
    if (this.projectId !== '') {
      if (this.bInitialLoad) {
        // Set the default dates using the Project start date.
        this.setSearchCriteria();
        this.bInitialLoad = false;
        this.refreshCharts();
      } else {
        this.aggregateHelperUpdated++;
        this.resize();
      }
    } else {
      if (this.bInitialLoad) {
        this.bInitialLoad = this.reportHelperLoading;
      }
      this.aggregateHelperUpdated++;
      this.resize();
    }
  }

  @Watch('reportHelper.isLoading')
  protected onReportHelperChanged(val: any, oldVal: any) {
    this.reportHelperLoading = this.reportHelper ? this.reportHelper.isLoading : false;
    if (!this.aggregateHelperLoading) {
      this.bInitialLoad = false;
    }
    this.reportHelperUpdated++;
    this.resize();
  }

  protected setSearchCriteria() {
    // Set the Expense Area search filter.
    this.searchCriteria.filters = [];
    this.searchCriteria.filters.push(getQsExpenseAreaFilter('agg-expense-type'));
    // TODO: Turn this back on when locations are enabled.
    // this.searchCriteria.filters.push(getQsRegionFilter('agg-region'));
    this.currentOrg = this.$store.getters['local/CURRENT_ORG'] !== '' ? this.$store.getters['local/CURRENT_ORG'] : '';
    this.aggSearchCriteria.hasPayer = this.currentOrg;

    const dateFilters: any = this.$store.getters['session/FILTER_DATES'] ? this.$store.getters['session/FILTER_DATES'] : null;
    if (this.baseRoute === '/insights') {
      if (dateFilters.dateFrom && dateFilters.dateTo) {
        this.searchCriteria.dateFrom = dateFilters.dateFrom;
        this.searchCriteria.dateTo = dateFilters.dateTo;
      } else {
        this.searchCriteria.dateFrom = this.selectedYear + '-01-01';
        this.searchCriteria.dateTo = this.selectedYear + '-12-31';
        this.updateSearchCriteria();
      }
    }
  }

  protected updateSearchCriteria() {
    // Set the Vuex store with the selected dates from and to.
    this.$store.commit('session/SET_FILTER_DATES', { dateFrom: this.searchCriteria.dateFrom, dateTo: this.searchCriteria.dateTo });
  }

  protected isRefreshing(): boolean {
    return this.bUpdateClicked && this.isLoading();
  }

  protected isLoading(): boolean {
    return (this.aggregateHelperLoading || this.reportHelperLoading || this.minYearLoading);
  }

  protected refreshCharts() {
    this.updateSearchCriteria();
    if (this.projectId !== '') {
      // Load the Project aggregate.
      this.searchCriteria.projects = this.projectId;
      if (this.bInitialLoad) {
        this.aggregateHelper = new AggregateHelper();
        this.aggregateHelper.getProject(this.projectId);
      } else {
        this.aggregateHelper.populateProjectDataLists(this.projectId, this.searchCriteria, this.aggSearchCriteria, false);
        if (this.reportHelper) {
          this.reportHelper.populateDataLists(this.currentOrg, this.searchCriteria);
        }
      }
    } else {
      // Load the Current Org aggregate.
      this.aggregateHelper = new AggregateHelper();
      this.aggregateHelper.populateVendorDataLists(this.currentOrg, this.searchCriteria, this.aggSearchCriteria, false);
      if (this.reportHelper) {
        this.reportHelper.populateDataLists(this.currentOrg, this.searchCriteria);
      }
    }
  }

  protected updateCharts() {
    this.bUpdateClicked = true;
    this.refreshCharts();
  }

  protected fullScreenChanged() {
    this.isFullScreen = !this.isFullScreen;
    if (!this.isFullScreen) {
      this.resizeCharts++;
    }
  }

  protected resize() {
    charts.resize();
  }

  private getKey(id: string): string {
    return id + '-' + this.aggregateHelperUpdated + '-' + this.reportHelperUpdated;
  }

  private getMinExpenseYear(): void {
    const expenseSearchCriteria: SearchExpenses = _.cloneDeep(EmptySearchExpenses);
    const searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    searchExpenses({page: "1", ipp: "1", sortby: "payment.effectiveDate", sortdir: "asc", terms: ""}, searchCriteria, expenseSearchCriteria).then((response) => {
      if (response.searchResults.results.length) {
        const oldestExpense = response.searchResults.results[0] as Expense;
        if (oldestExpense.payment.effectiveDate) {
          this.minYear = datetime.parseDate(oldestExpense.payment.effectiveDate).getFullYear();
          this.minYearLoading = false;
        }
      }
    });
  }

}
