











































import { Component, Vue, Prop } from 'vue-property-decorator';
import SpinnerComponent from '../components/SpinnerComponent.vue';

@Component({
  components: {
    SpinnerComponent,
  },
})
export default class MiniBarComponent extends Vue {
  @Prop() public isLoading: any;
  @Prop() public title: any;
  @Prop() public totalAmount: any;
  @Prop() public stacks: any;
  @Prop({ type: Array, required: true }) public stackCssClasses!: string[];
  @Prop({ type: Array, required: true }) public stackCssLegendClasses!: string[];
  @Prop() public containerCssClass: any;
  @Prop() public emptyStackCssClass: any;
  @Prop({ type: Boolean, default: false }) public printMode!: boolean;

  get printModeClasses() {
    return {
      sm12: !this.printMode,
    };
  }

  private index: any = -1;

  private getStackBorderOffset(index: number, totalStacks: number): string {
    const offset = index > 0 ? 2 : totalStacks === 1 ? 2 : (totalStacks - 1) * 2;
    return offset.toString() + 'px';
  }

  private getStack(stack: number, index: number, totalStacks: number): string {
    const stackWidth = this.getStackWidth(stack);
    const stackStyle = 'calc(' + stackWidth.toString() + '% - ' + this.getStackBorderOffset(index, totalStacks) + ')';
    return stackStyle;
  }

  private getStackWidth(stack: number): number {
    return stack > 100 ? 100 : stack;
  }

  private emptyPercentageWidth(stacks: any): number {
    if (stacks.length === 0) {
      return 0;
    } else {
      let total: number = 0;
      for (const stack of stacks) {
        total += stack;
      }
      return (100 - total);
    }
  }

  private emptyPercentage(stacks: any, totalStacks: number): string {
    const emptyPercentageWidth = this.emptyPercentageWidth(stacks);
    if (emptyPercentageWidth === 0) {
      return '0%';
    } else {
      return 'calc(' + emptyPercentageWidth.toString() + '% - ' + this.getStackBorderOffset(1, totalStacks) + ')';
    }
  }

  private getClass(index: any): string {
    return this.stackCssClasses[index] + (index === 0 ? ' mini-bar-first' : '') + ' mini-bar-first mini-bar-default';
  }

  private getLegendClass(index: any): string {
    return this.stackCssLegendClasses[index] + (index === 0 ? ' mini-bar-legend-first' : ' mini-bar-legend-last') + ' mini-bar-legend';
  }
}
