
import { Component, Prop } from 'vue-property-decorator';
import ChartComponent from './ChartComponent.vue';
import { StackedBarMeta } from '../store/models';
import { EmptyStackedBarMeta } from '../store/models-empty';
import * as colors from '../lib/colors';
import * as charts from '../lib/charts';
import * as _ from 'lodash';

@Component
export default class ClientChartComponent extends ChartComponent {

  @Prop() public aggregate: any;
  @Prop() public overallTotal: any;
  @Prop() public maxChartItems: any;

  // Chart data.
  private totalContributionsToCommunities: any = {};
  private totalContributionsToCommunitiesTitle: string = 'TOTAL CONTRIBUTIONS TO COMMUNITIES BY CLIENT';
  private totalContributionsToCommunitiesXLabel: string = '';
  private totalContributionsToCommunitiesYLabel: string = '';

  protected isRefreshing(): boolean {
    return false;
  }

  protected getOptions(): any {
    return this.totalContributionsToCommunities;
  }

  protected refreshCharts() {
    this.redrawComponentCharts();
  }

  protected redrawComponentCharts() {
      const stackedBarMeta: StackedBarMeta = _.cloneDeep(EmptyStackedBarMeta);
      stackedBarMeta.title = this.totalContributionsToCommunitiesTitle;
      stackedBarMeta.xAxis = this.totalContributionsToCommunitiesXLabel;
      stackedBarMeta.yAxis = this.totalContributionsToCommunitiesYLabel;
      stackedBarMeta.categories = charts.getTnbBarChartCategories(this.aggregate, this.maxChartItems, false);
      stackedBarMeta.series = this.overallTotal ? charts.getTnbBarChartSeries(this.aggregate, this.maxChartItems, 'Total Contributions', false, this.overallTotal, !this.overallTotal) : charts.getTnbBarChartSeries(this.aggregate, this.maxChartItems, 'Total Contributions', false, this.overallTotal, false, false, false);
      stackedBarMeta.colors =
        this.overallTotal ? [ colors.colorDirectIndigenousSpend ]
                          : [ colors.colorProjectBenefits, colors.colorEmployment];
      stackedBarMeta.totalStacks = 2;
      stackedBarMeta.formatNumbersToCurrency = true;
      stackedBarMeta.legendEnabled = !this.overallTotal;
      this.totalContributionsToCommunities = charts.getStackedBarFromMeta(stackedBarMeta);
      charts.resize();
      this.bInitialLoad = false;
  }
}
