
import { Component, Prop } from 'vue-property-decorator';
import ChartComponent from './ChartComponent.vue';
import { StackedBarMeta } from '../store/models';
import { EmptyStackedBarMeta } from '../store/models-empty';
import * as colors from '../lib/colors';
import * as charts from '../lib/charts';
import * as _ from 'lodash';

@Component
export default class DiverseSuppliersChartComponent extends ChartComponent {

  @Prop() public aggregate: any;
  @Prop() public diversityReferenceData: any;
  @Prop() public title: any;
  @Prop() public maxChartItems: any;

  // Chart data.
  private diverseSuppliers: any = {};
  private defaultTitle: string = 'Spend with Diverse Suppliers';

  protected isRefreshing(): boolean {
    return false;
  }

  protected getOptions(): any {
    return this.diverseSuppliers;
  }

  protected refreshCharts() {
    this.redrawComponentCharts();
  }

  protected redrawComponentCharts() {
    const stackedBarMeta: StackedBarMeta = _.cloneDeep(EmptyStackedBarMeta);
    stackedBarMeta.title = this.title ? this.title : this.defaultTitle;
    stackedBarMeta.xAxis = '';
    stackedBarMeta.yAxis = '';
    stackedBarMeta.categories = charts.getDiverseSupplierCategories(this.aggregate, this.diversityReferenceData);
    stackedBarMeta.series = charts.getDiverseSupplierSeries(this.aggregate, this.diversityReferenceData);
    stackedBarMeta.colors = [ colors.colorDarkPurple, colors.colorLightPurple ];
    stackedBarMeta.totalStacks = 1;
    stackedBarMeta.formatNumbersToCurrency = true;
    stackedBarMeta.legendEnabled = true;
    stackedBarMeta.bgColor = colors.colorLightGrey;
    this.diverseSuppliers = charts.getStackedBarFromMeta(stackedBarMeta);
    charts.resize();
    this.bInitialLoad = false;
  }
}
