






















































































































































































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import Highcharts, { chart } from "highcharts";

import SimpleChartComponent from './SimpleChartComponent.vue';
import SpinnerComponent from './SpinnerComponent.vue';
import TotalComponent from './TotalComponent.vue';
import TotalsItemComponent from './TotalsItemComponent.vue';
import MiniBarComponent from "../components/MiniBarComponent.vue";
import { ReportType } from './ReportHelper';

import { BulletGraphMeta, ImpactTargetsMeta, TotalsAggregate, TotalsItem } from '../store/models';
import { EmptyBulletGraphMeta, EmptyTotalsItem } from '../store/models-empty';
import * as charts from '../lib/charts';
import * as _ from 'lodash';
import loadBullet from "highcharts/modules/bullet";
import BeneficiaryChartComponent from '../components/BeneficiaryChartComponent.vue';
import ClientChartComponent from '../components/ClientChartComponent.vue';
import { convertToPercent } from '@/lib/currency';

loadBullet(Highcharts);

@Component({
  components: {
    TotalComponent,
    BeneficiaryChartComponent,
    ClientChartComponent,
    SimpleChartComponent,
    SpinnerComponent,
    TotalsItemComponent,
    MiniBarComponent,
  },
})
export default class IndigenousImpactComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public beneficiaryAggregate: any;
  @Prop() public clientAggregate: any;
  @Prop() public impactTargets: any;
  @Prop() public reportHelper: any;
  @Prop() public projectId: any;
  @Prop() public theme: any;
  @Prop() public reportMode: any;
  @Prop() public includeEmployment: any;
  @Prop() public hideTitle: any;

  private ReportType = ReportType;
  private updateArgs: any = charts.getUpdateArgs();

  private totalsItems: TotalsItem[] = [];
  private tnbItems: any = [];
  private tnbTotals: any = {};

  private isLoaded: boolean = false;

  private projectBenefitsTotalsItem: TotalsItem = _.cloneDeep(EmptyTotalsItem);
  private projectBenefitsChartData: any = {};
  private diverseProjectBenefitTarget: number = 0;
  private indigenousProjectBenefitTarget: number = 0;
  private projectBenefitsDiverseTotalsItem: TotalsItem = _.cloneDeep(EmptyTotalsItem);
  private projectBenefitsDiverseChartData: any = {};

  private contributionsTotalsItem: TotalsItem = _.cloneDeep(EmptyTotalsItem);
  private contributionsChartData: any = {};
  private indigenousCommunityContributionTarget: number = 0;
  private diverseCommunityContributionTarget: number = 0;
  private contributionsDiverseTotalsItem: TotalsItem = _.cloneDeep(EmptyTotalsItem);
  private contributionsDiverseChartData: any = {};

  private employmentTotalsItem: TotalsItem = _.cloneDeep(EmptyTotalsItem);
  private employmentChartData: any = {};
  private indigenousEmploymentTarget: number = 0;
  private diverseEmploymentTarget: number = 0;
  private employmentDiverseTotalsItem: TotalsItem = _.cloneDeep(EmptyTotalsItem);
  private employmentDiverseChartData: any = {};
  private totalEmployment: any[] = [];

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    if (this.aggregate) {
      this.getTargets();

      const totalsAggregate = (this.aggregate as TotalsAggregate);

      this.totalsItems.push({
        description: 'TOTAL TNB',
        percentageFormatted: totalsAggregate.tnb.total.percentageFormatted,
        amount: totalsAggregate.tnb.total.value,
        percentage: totalsAggregate.tnb.total.percentage,
        backgroundColor: this.colors.colorTotalTnb,
        color: this.colors.colorWhite,
        cssClass: 'totals-item-title',
        children: [],
        isFullWidth: false,
        rowLayout: 4,
        isCurrency: true,
        isFirst: true,
        hasContainer: false,
      });

      this.totalsItems.push({
        description: 'TOTAL INDIGENOUS TNB',
        percentageFormatted: totalsAggregate.tnb.overall.indigenous.percentageFormatted,
        amount: totalsAggregate.tnb.overall.indigenous.value,
        percentage: totalsAggregate.tnb.overall.indigenous.percentage,
        backgroundColor: this.colors.colorIndigenousTnb,
        color: this.colors.colorWhite,
        cssClass: 'totals-item-title',
        children: [],
        isFullWidth: false,
        rowLayout: 4,
        isCurrency: true,
        hasContainer: false,
      });

      this.totalsItems.push({
        description: 'TOTAL DIVERSE TNB',
        percentageFormatted: totalsAggregate.tnb.overall.diverse.percentageFormatted,
        amount: totalsAggregate.tnb.overall.diverse.value,
        percentage: totalsAggregate.tnb.overall.diverse.percentage,
        backgroundColor: this.colors.colorDiverseTnb,
        color: this.colors.colorWhite,
        cssClass: 'totals-item-title',
        children: [],
        isFullWidth: false,
        rowLayout: 4,
        isCurrency: true,
        isLast: true,
        hasContainer: false,
      });

      this.projectBenefitsTotalsItem =
        this.getTotalsItem('PROJECT BENEFITS',
                            totalsAggregate.tnb.projectBenefits.indigenous.percentageFormatted,
                            totalsAggregate.tnb.projectBenefits.indigenous.value,
                            this.colors.colorProjectBenefitsTnb);
      this.projectBenefitsChartData = this.getTargetBulletGraph(this.colors.colorProjectBenefitsTnb, this.indigenousProjectBenefitTarget, totalsAggregate.tnb.projectBenefits.indigenous.percentage);

      this.projectBenefitsDiverseTotalsItem =
        this.getTotalsItem('DIVERSE PROJECT BENEFITS',
                            totalsAggregate.tnb.projectBenefits.diverse.percentageFormatted,
                            totalsAggregate.tnb.projectBenefits.diverse.value,
                            this.colors.colorProjectBenefitsTnb);
      this.projectBenefitsDiverseChartData = this.getTargetBulletGraph(this.colors.colorProjectBenefitsTnb, this.diverseProjectBenefitTarget, totalsAggregate.tnb.projectBenefits.diverse.percentage);

      this.contributionsTotalsItem =
        this.getTotalsItem('COMMUNITY CONTRIBUTIONS',
                            totalsAggregate.tnb.communityContributions.indigenous.percentageFormatted,
                            totalsAggregate.tnb.communityContributions.indigenous.value,
                            this.colors.colorIndigenousTnb);
      this.contributionsChartData = this.getTargetBulletGraph(this.colors.colorIndigenousTnb, this.indigenousCommunityContributionTarget, totalsAggregate.tnb.communityContributions.indigenous.percentage);

      this.contributionsDiverseTotalsItem =
        this.getTotalsItem('DIVERSE COMMUNITY CONTRIBUTION',
                            totalsAggregate.tnb.communityContributions.diverse.percentageFormatted,
                            totalsAggregate.tnb.communityContributions.diverse.value,
                            this.colors.colorIndigenousTnb);
      this.contributionsDiverseChartData = this.getTargetBulletGraph(this.colors.colorIndigenousTnb, this.diverseCommunityContributionTarget, totalsAggregate.tnb.communityContributions.diverse.percentage);

      this.employmentTotalsItem =
        this.getTotalsItem('INDIGENOUS EMPLOYMENT',
                            totalsAggregate.tnb.employment.indigenous.percentageFormatted,
                            totalsAggregate.tnb.employment.indigenous.value,
                            this.colors.colorEmploymentTnb);
      this.employmentChartData = this.getTargetBulletGraph(this.colors.colorEmploymentTnb, this.indigenousEmploymentTarget, totalsAggregate.tnb.employment.indigenous.percentage);

      // TNB data table.
      this.employmentDiverseTotalsItem =
        this.getTotalsItem('DIVERSE EMPLOYMENT (LESS INDIGENOUS)',
                            totalsAggregate.tnb.employment.diverse.percentageFormatted,
                            totalsAggregate.tnb.employment.diverse.value,
                            this.colors.colorEmploymentTnb);
      this.employmentDiverseChartData = this.getTargetBulletGraph(this.colors.colorEmploymentTnb, this.diverseEmploymentTarget, totalsAggregate.tnb.employment.diverse.percentage);

      this.tnbItems.push({ type: 'Indigenous Tangible Net Benefits',
                           totalProjectBenefits: totalsAggregate.tnb.projectBenefits.indigenous.valueFormatted + ' (' + totalsAggregate.tnb.projectBenefits.indigenous.percentageFormatted + ')',
                           totalEmployment: totalsAggregate.employment.indigenous.wages.valueFormatted + ' (' + totalsAggregate.employment.indigenous.wages.percentageFormatted + ')',
                           total: totalsAggregate.employment.indigenous.wages.value + totalsAggregate.tnb.projectBenefits.indigenous.value });
      this.tnbItems.push({ type: 'Diverse Tangible Net Benefits',
                            totalProjectBenefits: totalsAggregate.tnb.projectBenefits.diverse.valueFormatted + ' (' + totalsAggregate.tnb.projectBenefits.diverse.percentageFormatted + ')',
                            totalEmployment: totalsAggregate.employment.diverse.wages.valueFormatted + ' (' + totalsAggregate.employment.diverse.wages.percentageFormatted + ')',
                            total: totalsAggregate.employment.diverse.wages.value + totalsAggregate.tnb.projectBenefits.diverse.value });

      const totalProjectBenefits: number = totalsAggregate.tnb.projectBenefits.diverse.value + totalsAggregate.tnb.projectBenefits.diverse.value;
      const totalEmployment: number = totalsAggregate.employment.diverse.wages.value + totalsAggregate.employment.indigenous.wages.value;
      this.tnbTotals = {
        totalProjectBenefits,
        totalEmployment,
        total: totalProjectBenefits + totalEmployment,
      };

      // Total Employment minibar.
      this.totalEmployment = [
        {
          percentage: convertToPercent(
            totalsAggregate.employment.indigenous.wages.value,
          totalsAggregate.employment.overall.wages.value,
            false,
            false,
          ),
          amount: totalsAggregate.employment.indigenous.wages.value,
          label: "INDIGENOUS EMPLOYMENT",
        },
        {
          percentage: convertToPercent(
            (totalsAggregate.employment.overall.wages.value - totalsAggregate.employment.indigenous.wages.value),
            totalsAggregate.employment.overall.wages.value,
            false,
            false,
          ),
          amount: (totalsAggregate.employment.overall.wages.value - totalsAggregate.employment.indigenous.wages.value),
          label: "NON-INDIGENOUS EMPLOYMENT",
        },
      ];

      this.isLoaded = true;
    }
  }

  private getTotalsItem(description: string, percentageFormatted: string, itemValue: number, color: string): TotalsItem {
    const totalsItem: TotalsItem = _.cloneDeep(EmptyTotalsItem);
    totalsItem.description = description;
    totalsItem.percentageFormatted = percentageFormatted;
    totalsItem.percentageDescription = 'of Total Project Revenue';
    totalsItem.amount = itemValue;
    totalsItem.color = color;
    totalsItem.backgroundColor = this.colors.colorWhite;
    totalsItem.isFullWidth = true;
    totalsItem.isCurrency = true;
    totalsItem.isSmallPercentage = true;
    return totalsItem;
  }

  private getTargetBulletGraph(color: string, target: number, chartValue: number): BulletGraphMeta {
    const chartMeta: BulletGraphMeta = _.cloneDeep(EmptyBulletGraphMeta);
    chartMeta.marginLeft = 10;
    chartMeta.exportingEnabled = false,
    chartMeta.height = 90;
    chartMeta.theme = this.theme;
    chartMeta.legendColor = color;
    const plotBands: any = [{
      from: 0,
      to: 100,
      color: this.colors.colorWhite,
    }];
    chartMeta.series = [{
      data: [{
        y: chartValue,
        target,
        color,
      }],
    }];
    if (chartValue > 0 || target) {
      chartMeta.plotBands = plotBands;
    }
    return charts.getBulletGraphFromMeta(chartMeta);
  }

  private getTargets() {
    const impactTargets = this.impactTargets as ImpactTargetsMeta;
    this.indigenousCommunityContributionTarget = this.zeroIfUndefined(impactTargets.indigenousCommunityContribution);
    this.diverseCommunityContributionTarget = this.zeroIfUndefined(impactTargets.diverseCommunityContribution);
    this.indigenousEmploymentTarget = this.zeroIfUndefined(impactTargets.indigenousEmployment);
    this.diverseEmploymentTarget = this.zeroIfUndefined(impactTargets.diverseEmployment);
    this.indigenousProjectBenefitTarget = this.zeroIfUndefined(impactTargets.indigenousProjectBenefit);
    this.diverseProjectBenefitTarget = this.zeroIfUndefined(impactTargets.diverseProjectBenefit);
  }

  private getChartClass() {
    return !this.reportMode ? "xs12 lg6" : "xs12";
  }
}
