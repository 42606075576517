























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import Highcharts from 'highcharts';
import SimpleChartComponent from './SimpleChartComponent.vue';
import TotalComponent from './TotalComponent.vue';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import { BulletGraphMeta, TotalsAggregate, TotalsItem } from '../store/models';
import { EmptyBulletGraphMeta, EmptyTotalsItem } from '../store/models-empty';
import * as charts from '../lib/charts';
import * as _ from 'lodash';
import loadBullet from "highcharts/modules/bullet";

loadBullet(Highcharts);

@Component({
  components: {
    SimpleChartComponent,
    SpinnerComponent,
    TotalComponent,
  },
})
export default class SocialProcurementCompactComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public impactTargets: any;
  @Prop() public reportHelper: any;
  @Prop() public projectId: any;
  @Prop() public hideTable: any;
  @Prop() public hideTargets: any;
  @Prop() public reportMode: any;
  @Prop() public showExtraTotals: any;
  @Prop() public hideDiversityMarker: any;
  @Prop() public theme: any;

  private chartData: any = {};
  private socialTotalsItem: TotalsItem = _.cloneDeep(EmptyTotalsItem);
  private totalsItems: TotalsItem[] = [];
  private extraTotalsItems: TotalsItem[] = [];
  private socialItems: any = [];
  private socialTotals: any = {};
  private socialTarget: number = 0;

  private isLoaded: boolean = false;

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    if (this.aggregate) {
      this.getTargets();
      const totalsAggregate = (this.aggregate as TotalsAggregate);

      const socialValue: number = this.formatToNumber(totalsAggregate.socialProcurement.overall.percentage, 2) as number;
      this.socialTotalsItem.description = 'SOCIAL PROCUREMENT';
      this.socialTotalsItem.percentageFormatted = totalsAggregate.socialProcurement.overall.percentageFormatted;
      this.socialTotalsItem.amount = totalsAggregate.socialProcurement.overall.value;
      this.socialTotalsItem.isFullWidth = true;

      if (!this.hideTargets) {
        const chartMeta: BulletGraphMeta = _.cloneDeep(EmptyBulletGraphMeta);
        chartMeta.marginLeft = 10;
        chartMeta.exportingEnabled = false,
        chartMeta.height = 147;
        chartMeta.theme = this.theme;

        const plotBands: any = [{
          from: 0,
          to: 100,
          color: this.colors.colorWhite,
        }];
        chartMeta.series = [{
          data: [{
            y: socialValue,
            target: this.socialTarget,
            color: this.colors.colorSocial,
          }],
        }];
        if (socialValue > 0 || this.socialTarget) {
          chartMeta.plotBands = plotBands;
        }
        this.chartData = charts.getBulletGraphFromMeta(chartMeta);
      }
      this.isLoaded = true;
    }
  }

  private getTargets() {
    this.socialTarget = this.impactTargets ? this.zeroIfUndefined(this.impactTargets.socialProcurement) : 0;
  }
}
