























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import Highcharts, { chart } from 'highcharts';
import SimpleChartComponent from './SimpleChartComponent.vue';
import TotalComponent from './TotalComponent.vue';
import SpinnerComponent from './SpinnerComponent.vue';
import { TotalsAggregate, StackedBarMeta, ResourceSearchResult, DiversityTotalsMeta, Aggregate, DiversityTotalsType } from '../store/models';
import { EmptyStackedBarMeta } from '../store/models-empty';
import * as charts from '../lib/charts';
import * as _ from 'lodash';
import loadBullet from "highcharts/modules/bullet";

loadBullet(Highcharts);

@Component({
  components: {
    SimpleChartComponent,
    SpinnerComponent,
    TotalComponent,
  },
})
export default class ThirdPartyInsightsComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public diversityAggregate: any;
  @Prop() public diversityReferenceData: any;

  private chartData: any = {};
  private isLoaded: boolean = false;

  protected mounted() {
    this.refreshCharts();
  }

  private getSeriesColor(code: string) {
    switch (code) {
      case 'certifiedBCorp':
        return this.colors.colorThirdPartyStackedBCorp;
      case 'certifiedLivingWageEmployer':
        return this.colors.colorThirdPartyLivingWageEmployer;
      case 'certifiedSocialEnterprise':
        return this.colors.colorThirdPartyCertifiedSocialEnterprise;
      case 'certifiedC3':
        return this.colors.colorThirdPartyCommunityContributionCompany;
      case 'communityServiceCoop':
        return this.colors.colorThirdPartyCommunityServiceCoops;
      case 'notForProfitIndigenous':
        return this.colors.colorThirdPartyNotForProfitIndigenous;
      default:
        return '#FFFFFF';
    }
  }

  private refreshCharts() {
    if (this.aggregate) {
      const chartMeta: StackedBarMeta = _.cloneDeep(EmptyStackedBarMeta);
      chartMeta.title = '';
      chartMeta.xAxis = '';
      chartMeta.yAxis = '';
      chartMeta.totalStacks = 1;
      chartMeta.categories = [ '' ];
      const series: any[] = [];

      const totalsMeta: DiversityTotalsMeta = ((this.diversityAggregate as ResourceSearchResult).searchResults.results as Aggregate[])[0].totals as DiversityTotalsMeta;
      const impactSocial: DiversityTotalsType|undefined = totalsMeta.esgByClass !== undefined ? totalsMeta.esgByClass.find((x) => x.diversityClass === 'impactSocial') : undefined;
      if (impactSocial !== undefined) {
        for (const diversityType of impactSocial.diversityTypes) {
          const total: number = charts.getDiversityTotal(diversityType);
          const name: string = this.getDiversityNameFromCode(this.diversityReferenceData, diversityType.diversityType, 'impactSocial');
          if (name !== '') {
            chartMeta.categories.push(name);
            series.push({ name, index: 0, stack: 'stack1', data: [ total ], color: this.getSeriesColor(diversityType.diversityType) });
          }
        }
      }
      chartMeta.series = series;
      chartMeta.totalStacks = 1;
      chartMeta.formatNumbersToCurrency = true;
      chartMeta.legendEnabled = true;
      chartMeta.legendRightAlign = true;
      chartMeta.height = 200;
      chartMeta.legendColor = this.colors.colorDarkBlue;
      chartMeta.exportingEnabled = true;
      chartMeta.isPercentage = false;
      chartMeta.isCurrency = true;
      chartMeta.yAxisVisible = true;
      this.chartData = charts.getStackedBarFromMeta(chartMeta, null, null, 35);
      this.isLoaded = true;
    }
  }
}
