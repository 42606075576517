























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import Highcharts, { chart } from 'highcharts';
import SimpleChartComponent from './SimpleChartComponent.vue';
import TotalComponent from './TotalComponent.vue';
import SpinnerComponent from './SpinnerComponent.vue';
import { TotalsAggregate, StackedBarMeta } from '../store/models';
import { EmptyStackedBarMeta } from '../store/models-empty';
import * as charts from '../lib/charts';
import * as _ from 'lodash';
import loadBullet from "highcharts/modules/bullet";

loadBullet(Highcharts);

@Component({
  components: {
    SimpleChartComponent,
    SpinnerComponent,
    TotalComponent,
  },
})
export default class IndigenousProcurementInsightsComponent extends BaseComponent {

  @Prop() public aggregate: any;

  private chartData: any = {};
  private isLoaded: boolean = false;

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    if (this.aggregate) {
      const totalsAggregate = (this.aggregate as TotalsAggregate);
      const chartMeta: StackedBarMeta = _.cloneDeep(EmptyStackedBarMeta);
      chartMeta.title = '';
      chartMeta.xAxis = '';
      chartMeta.yAxis = '';
      chartMeta.totalStacks = 1;
      chartMeta.categories = [ '' ];
      const series: any[] = [];
      series.push({ name: 'Certified Indigenous Owned Business Spend ' + totalsAggregate.indigenous.certifiedBusinessSpend.valueFormatted + ' (' + totalsAggregate.indigenous.certifiedBusinessSpend.percentageFormatted + ')', index: 0, stack: 'stack1', data: [ totalsAggregate.indigenous.certifiedBusinessSpend.value ], color: this.colors.colorCertifiedIndigenousOwned });
      series.push({ name: 'Indigenous Community Owned Business Spend ' + totalsAggregate.indigenous.communityOwnedSpend.valueFormatted + ' (' + totalsAggregate.indigenous.communityOwnedSpend.percentageFormatted + ')', index: 1, stack: 'stack1', data: [ totalsAggregate.indigenous.communityOwnedSpend.value ], color: this.colors.colorIndigenousOwned });
      series.push({ name: 'Relationship Spend ' + totalsAggregate.indigenous.relationshipSpend.valueFormatted + ' (' + totalsAggregate.indigenous.relationshipSpend.percentageFormatted + ')', index: 2, stack: 'stack1', data: [ totalsAggregate.indigenous.relationshipSpend.value ], color: this.colors.colorRelationship });
      series.push({ name: 'Self Declared Indigenous Owned Business Spend ' + totalsAggregate.indigenous.selfDeclaredBusinessSpend.valueFormatted + ' (' + totalsAggregate.indigenous.selfDeclaredBusinessSpend.percentageFormatted + ')', index: 3, stack: 'stack1', data: [ totalsAggregate.indigenous.selfDeclaredBusinessSpend.value ], color: this.colors.colorSelfDeclared });
      chartMeta.series = series;
      chartMeta.totalStacks = 1;
      chartMeta.formatNumbersToCurrency = true;
      chartMeta.legendEnabled = true;
      chartMeta.legendRightAlign = true;
      chartMeta.height = 200;
      chartMeta.legendColor = this.colors.colorDarkBlue;
      chartMeta.exportingEnabled = true;
      chartMeta.isPercentage = false;
      chartMeta.isCurrency = true;
      chartMeta.yAxisVisible = true;
      this.chartData = charts.getStackedBarFromMeta(chartMeta, null, null, 35);
      this.isLoaded = true;
    }
  }
}
